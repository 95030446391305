import React from 'react'
import '../Styles/Careers.css'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import BottomCarousel from '../Components/BottomCarousel'
function Careers() {

return (
<div className="App">
   <header className="header">
      <section className='backgroung-image-careers'>
         <Navbar/>
         <div className='header-Careers-contents'>
            <h1>WISH YOU JOIN US?</h1>
            <h2>SHARE YOUR PART</h2>
         </div>
      </section>
   </header>
   <main className="main">
      {/* <!--Content--> */}
      <div className="innerContent career-inner">
         <div className="careerOut">
            <div className="container">
               <div className="col-md-6 careers-left">
                  <h2>available jobs</h2>
                  <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h3 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      Executive -Sales, Inside. 
      </button>
    </h3>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <span><strong>Experience :</strong> 3-4 years</span>  <span><strong>Location :</strong> Chennai & Bangalore</span>
                           <h4 className='pt-4'>  Job Description | Responsibility</h4>
                           <p>high profile appointment fixing | meeting calendar initiation | meeting follow ups.
                              Clientele data maintenance | willing to travel to complete the CAF Protocols.
                           </p>
                           <h4>Skills required</h4>
                           <p>interested in people handling and networking. Should possess with high telephone mannerism, strong verbal and written communication , along with corporate e-mail etiquettes</p>
                           <h4>Academic Qualification </h4>
                           <p>Any Degree</p>
                           <h4>Salary </h4>
                           <p>At par with Service sales - Industry.</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h3 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Subject Matter Expert - level 3, Data Communication. 
      </button>
    </h3>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <span><strong>Experience </strong> 4-7 years</span>  <span><strong>Location :</strong> Chennai</span>
                           <h4 className='pt-4'> Job Description | Responsibility</h4>
                           <p>Knowledge Transfer for Level,1~3 for Network - Wired | Wireless | VMs - Servers and Storage</p>
                           <h4>Skills required </h4>
                           <p>interested in Learning Technical Skill and its certification with due practices | Interested in Knowledge Transfer by setting up an example with brand
                              conscious. Should have basic acumen in people handling and networking . Strong in IPv4|IPv6 ,IGP and BGP along with Server Knowledge -
                              Theory,Practical and TShoot.
                           </p>
      </div>
    </div>
  </div>
</div>
               </div>
               <div className="col-md-6">
                  <h2>APPLY TO YOUR PREFERRED JOB</h2>
                  <form action="career.php" method="POST" name="form" id="form" enctype="multipart/form-data">
                     <div className="form-group">
                        <input type="text" name="name" className="text-input form-control" pattern="[a-zA-Z][a-zA-Z ]+[a-zA-Z]$" value="" placeholder="Full Name*" required />
                     </div>
                     <div className="form-group">
                        <input type="text" name="email" className="text-input form-control" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" value="" placeholder="Email Address*" required />
                     </div>
                     <div className="form-group">
                        <input type="text" name="phone" className="text-input form-control" maxlength="10" minlength="10" pattern="[0-9]{10}" value="" placeholder="Phone Number*" required />
                     </div>
                     <div className="form-group">
                        <input type="text" name="city" className="text-input form-control" value="" placeholder="Town / City*" required />
                     </div>
                     <div className="form-group">
                        <select name="job">
                           <option>Job Position</option>
                           <option value="Functional area">Functional area</option>
                           <option value="Functional area">Subject Matter Expert,ATD</option>
                           <option value="Functional area"> Full stack developer</option>
                           <option value="Functional area">UI/UX designer</option>
                           <option value="Functional area">Marketing and sales expert</option>
                           <option value="Functional area">Intern, L&D</option>
                        </select>
                     </div>
                     <div className="form-group">
                        <input type="text" name="experience" className="text-input form-control" value="" placeholder="Years of Experience*" required />
                     </div>
                     <div className="form-group file">
                        <label>  <input id="input-1" type="file" name="upload_file" className="file" value="" />  <span>PDF / JPEG / Word format</span></label>
                     </div>
                     <div className="form-group last">
                        <textarea  placeholder="Talk About Yourself*"></textarea>
                     </div>
                     <div className="col-md-12">
                        <div className="form-group">
                           <button name="send" type="submit" className="btn btn-default submit" value="SUBMIT">Submit</button>
                           <input type="hidden" name="submit" value="submit" />
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
      <BottomCarousel/>
   </main>
   <Footer/>
</div>
);
}
export default Careers;