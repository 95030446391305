import React, { useState } from 'react';

function SkillMenuBar() {
    const [isNavOpen, setIsNavOpen] = useState(false);

    const handleNavToggle = () => {
      setIsNavOpen(!isNavOpen);
    };
  return (
    <div>
         <nav className={`navbar ${isNavOpen ? 'open' : ''}`}>
      <div className="nav-toggle" onClick={handleNavToggle}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
      <ul className={`nav-links ${isNavOpen ? 'open' : ''}`}>
        <li><a href="noetc-science">SoSE/noetic Science</a></li>
        <li><a href="business-skill">Business Skill</a></li>
        <li><a href="leadership-management">LeaderShip And Management</a></li>
        <li><a href="behavioural-skill-training">Behavioural Skill Program</a></li>
        <li><a href="coaching">Coaching</a></li>
        <li><a href="Cross-cultue">Cross-Culture</a></li>

      </ul>
    </nav>
    </div>
  );
}

export default SkillMenuBar;
