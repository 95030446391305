import React, { useState } from 'react';
import '../Styles/Coaching.css'
import nocPiechat from '../assets/images/coaching-img.jpg'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import BottomCarousel from '../Components/BottomCarousel'
import SkillMenuBar from '../Components/SkillMenuBar';
import LearningDevelopmentForm from './../Components/LearningDevelopmentForm';
function Coaching() {
 
  
return (
<div className="App">
   <header className="header">
      <section className='backgroung-image-coaching'>
         <Navbar/>
         <div className='header-about-content'>
            <h1>.</h1>
         </div>
      </section>
    {/* menu Start */}
    <SkillMenuBar/>
       {/* Header Menu End */}
   </header>
   <main className="main">
      <section className='container about-success'>
         <div className='Testimonials-success-work'>
            {/* <!------what start--------> */}
            <div className="testimonials-bg">
               <div className="contai">
                  <h5> SERVICES</h5>
                  <h2><span>Coaching </span></h2>
                  <div className='row'>
                     <p>When organizations grow at a rapid pace, initiatives that drive growth happen daily, these include restructuring the way teams work together, and bringing in new processes that improve the quality of work and productivity. With all the noise about growth, a lot of questions get asked. However, the only question that baffles many organizations is, do we have the leadership pipeline to sustain and increase the rate of all-round growth? </p>
                     <p>To answer all the questions above, we need to develop a continuous pipeline of young and vibrant leaders. This again leads us to another question: How do we do this? The answer is “Coaching”</p>
                     <p className="what-we-dossw"><span className="what-we-d">Why</span> Coaching?</p>
                     <p>Growth creates a climate of change and this constant climate of change causes insecurity, reduced motivation, disengagement, need for dynamic leadership and new ways of working. Coaches are the perfect people to have around to facilitate the process of rapid and dynamic leadership development. Research shows that coaching can help organizations achieve their business goals and reap benefits beyond these business goals through: <span>Increased Productivity ║ Positive People ║ Return on Investment ║ Satisfied Clients</span></p>
                  </div>
                  <div>
                     <img className="coaching-img" src={nocPiechat}/>
                  </div>
               </div>
            </div>
         </div>
      </section>
      {/* How do you coach start */}
      <div className='how-do-coach-img '>
         <div className='container pt-5 pb-5'>
            <p className="why"><span className="why-choaching">How</span> do we Coach?</p>
            <p>By understanding that coaching offers many benefits. Brix Coaching Network (a panel of trained coaches) create significant, measurable improvements in performance. They do this by agreeing on performance metrics, tracking results, and having appropriate conversations to keep people accountable. With the intention of reaping these benefits and using the benefits to enable organizational growth. Coaching builds organizational capacity by developing leaders. With coaching, people gain insights into their own strengths and capabilities, embrace the vision and potential of their organization, and take responsibility for moving the organization forward</p>
            <p>To make this endeavor world className Brix Coaching Network has picked coaches who have been accredited through an ICF accredited coach training program which leads to the “Associate Certified Coach” (ACC) / “Professional Certified Coach” credentials from the ICF.</p>
         </div>
      </div>
      {/* How do you coach end */}
      {/* <!------success end--------> */}
      {/* program form Inner start */}
      <LearningDevelopmentForm/>
      {/* program form end */}

      {/* Brand Section Start */}
      <BottomCarousel/>
      {/* Brand Section end */}
   </main>
   {/* <!------footer start--------> */}
   <Footer/>
   {/* <!------footer end--------> */}
</div>
);
}
export default Coaching;