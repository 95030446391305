import React from "react";

class NewForm extends React.Component {
    constructor() {
        super();
        this.state = {subject:"",body:"",name:"",email:"",phone:"",message:""};
    }
    
    validateForm = event => {
        var subject = document.getElementById("subject").value;
        var body = document.getElementById("body").value;
        var name = document.getElementById("name").value;
        var email = document.getElementById("email").value;
        var phone = document.getElementById("phone").value;
        var message = document.getElementById("body").message;
        window.location.href = "mailto:ramprabhuBalasubramaniyan@gmail.com?subject="+subject+ "&body=" +body;
        event.preventDefault();
        return false
    }
    render() {
        return (
            <div>
                
            <h2>Contact Us</h2>
             <div className="container" id='mailme'>
                <div className="row">
                  <div className="col sm-8 offset-md-2">
                    <form onSubmit={this.validateForm} target="_blank" method="GET">
                        <div className="form-group form-group-lg">
                           <label className="skills" for="subjects">
                         <strong>
                            Name
                         </strong>
                           </label>
                           <input type="text" id="subject" className="control-form " required/>
                        </div>
                    
                        <div className="form-group form-group-lg">
                           <label className="skills" for="Body">
                         <strong>
                            Feedback
                         </strong>
                           </label>
                           <textarea id="body" className="control-form" row='5' required ></textarea>
                        </div>
                        <div className="form-group form-group-lg">
                           <label className="skills" for="Body">
                         <strong>
                            Feedbacks
                         </strong>
                           </label>
                           <textarea id="body" className="control-form" row='5' required ></textarea>
                        </div>
                        <input type="submit" value="send" id="navigation" className="btn btn-primary"></input>
                    </form>
                  </div>
                </div>
             </div>

             {/* all over................ */}
             {/* <section className='contact-section'>
         <div className="contactForms">
            <div className="container id='mailme'">
               <h3 className='contactForms-heading'>Get In Touch</h3>
               <p>Please drop in your details and we will get in touch with you shortly.</p>
               <div className="careerFormIns">
                  <form  onSubmit={this.validateForm} target="_blank" method="GET">
                     <div className="col-md-6 cfleft">
                        <div className="form-groups">
                           <input type="text" id="name" className="text-input form-control" pattern="[a-zA-Z][a-zA-Z ]+[a-zA-Z]$"  placeholder="Name *" required />
                        </div>
                        <div className="form-groups">
                           <input type="text" id="email" className="text-input form-control" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"placeholder="Email *" required />
                        </div>
                     </div>
                     <div className="col-md-6 cfright">
                        <div className="form-groups">
                           <input type="text" id="phone" className="text-input form-control" maxlength="10" minlength="10" pattern="[0-9]{10}"  placeholder="Phone *" required />
                        </div>
                     
                     </div>
                     <div className="col-md-12">
                        <div className="form-groups">
                           <textarea id="body" cols="" placeholder="Enter your message"  rows=""></textarea>
                        </div>
                     </div>
                     <div className="col-md-12">
                        <div className="form-groups">
                           <button className='submit-Btn' type="submit" class="btn btn-default submit">Submit</button>
                           <input type="submit" value="send" id="navigation"  />
                        </div>
                     </div>
                  </form>
                  
               </div>
            </div>
         </div>
      </section> */}
          </div>
        )
    }
}
export default NewForm;