import React from 'react'
import { Link } from 'react-router-dom'
import brixLogo from "../assets/images/brixLogo.png"
import menu from '../assets/images/menu.png'
import { FaLinkedin, FaEnvelope  } from 'react-icons/fa';
import '../Styles/Navbar.css'
function Navbar() {
return (

<div className='navbar-section pt-3'>
   
   <div className="container ">
      <nav className="flex flex-jc-sb flex-ai-c ">
         <div className="logo">
            <a href="/">
            <img src={brixLogo} alt="logo" />
            </a>
         </div>
         <button className="btn " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><img src={menu}></img></button>
         <div className="offcanvas offcanvas-end back-bg" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header">
               <h5 className="offcanvas-title" id="offcanvasRightLabel">MENU</h5>
               <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
               <Link  to='/'>
               Home</Link> 
               <hr/>
            </div>
            <div className="offcanvas-body">
               <Link to='/about'>
               About</Link>
               <hr/>
            </div>
            <div className="offcanvas-body">
              <Link to="/alliances" className="dropdown-toggle" id="servicesDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
               Services
               </Link>
               <div className="dropdown-menu custom-dropdown-menu" aria-labelledby="servicesDropdown">
                  <div className="offcanvas-body">
                     <Link className="dropdown-item" to="/advanced-technology-domain">
                     Advance Technology Domain</Link>
                  </div>
                  <div className="offcanvas-body">
                     <Link className="dropdown-item" to="/domain-compliances-gov">
                     Domain Compliances & Governance</Link>
                  </div>
                  <div className="offcanvas-body">
                     <Link className="dropdown-item" to="/professional-service-domain">
                     Professional Service Domain</Link>
                  </div>
                  <div className="offcanvas-body paddind-fonts">
                     <Link >
                     Learning & Development Domain</Link>
                     <div className="offcanvas-body paddind-font">
                        <Link className="dropdown-item " to="/noetc-science">
                        -    S0SE / noetic Science</Link>
                     </div>
                     <div className="offcanvas-body paddind-font">
                        <Link className="dropdown-item" to="/business-skill">
                        -    Business Skill</Link>
                     </div>
                     <div className="offcanvas-body paddind-font">
                        <Link className="dropdown-item" to="/leadership-management">
                        -    Leadership and Management</Link>
                     </div>
                     <div className="offcanvas-body paddind-font">
                        <Link className="dropdown-item" to="/behavioural-skill-training">
                        -    Behavioural Skill Program</Link>
                     </div>
                     <div className="offcanvas-body paddind-font">
                        <Link className="dropdown-item" to="/coaching">
                        -    Coaching</Link>
                     </div>
                     <div className="offcanvas-body paddind-font">
                        <Link className="dropdown-item" to="/Cross-culture">
                        -   Cross-Culture</Link>
                     </div>
                  </div>
               </div>
               <hr />
            </div>
            <div className="offcanvas-body">
               <Link to='/alliances'>
               Our Alliances</Link> 
               <hr/>
            </div>
            <div className="offcanvas-body">
               <Link to='/testimonials'>
               Testimonials</Link> 
               <hr/>
            </div>
            <div className="offcanvas-body">
               <Link to='/careers'>
               Careers</Link> 
               <hr/>
            </div>
            <div className="offcanvas-body">
               <Link to='/contact'>
               Contact Us</Link> 
               <hr/>
            </div>
            <div className="offcanvas-body">
               <ul className="social-media">

                  <li>
                     <a className="fb-ic mr-3" href="https://in.linkedin.com/company/brixnetwork ">
                        <FaLinkedin />
                     </a>
                  </li>
                  <li>
                        <a className="fb-ic mr-3" href="mailto:info@brixnet.in">
                           <FaEnvelope />
                        </a>
                     </li>
               </ul>
            </div>
         </div>
      </nav>
   </div>
</div>
);
}
export default Navbar;