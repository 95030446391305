import React from 'react'
import  '../Styles/BottomCarousel.css'
import sponser1 from '../assets/images/zee-tv.jpg'
import sponser2 from '../assets/images/wipro.jpg'
import sponser3 from '../assets/images/volkswagen.jpg'
import sponser4 from '../assets/images/tata-motors.jpg'
import sponser5 from '../assets/images/intel.jpg'
import sponser6 from '../assets/images/insight-automation.jpg'
import sponser7 from '../assets/images/mu-sigma.jpg'
import sponser8 from '../assets/images/minacs.jpg'
import sponser9 from '../assets/images/accel.jpg'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const BottomCarousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true, // Enable automatic sliding
        autoplaySpeed: 2000, // Set the speed of automatic sliding (in milliseconds)
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

  
  return (
    <section className='bottom-callAction'>
    <div className="callAction">
       <div className="container">
          <h2 ><span>Call to</span>  Action</h2>
          <p className='Brix-Call-to-action'><span>BRIX | enabling excellence a habit</span></p>
          <p>A dedicated service awaits your queries.<br/> 
             Get in touch with us.  
          </p>
          <a data-toggle="modal" data-target="#myModal" href="/contact">Call Us Now</a>
       </div>
    </div>
    <div className='scroll-card-bg'>
        <div className="container backg-color">
        <Slider {...settings}>
                 <div className='  owl-carouselss'>
                 <div className="item"><img src={sponser1} alt="Image 1" /></div> 
                 </div>
                    <div className=' owl-carouselss'>
                    <div className="item "><img src={sponser2} alt="Image 2" /></div>
                    </div>
                    <div className='owl-carouselss'>
                    <div className="item "><img src={sponser3} alt="Image 3" /></div>
                    </div>
                    <div className=' owl-carouselss'>
                    <div className="item"><img src={sponser4} alt="Image 5" /></div>
                    </div>
                    <div className=' owl-carouselss'>
                    <div className="item"><img src={sponser5} alt="Image 6" /></div>
                    </div>
                    <div className='owl-carouselss'>
                    <div className="item"><img src={sponser6} alt="Image 3" /></div>
                    </div>
                    <div className=' owl-carouselss'>
                    <div className="item "><img src={sponser7} alt="Image 7" /></div>
                    </div>
                    <div className='owl-carouselss'>
                    <div className="item"><img src={sponser8} alt="Image 8" /></div>
                    </div>
                    <div className='owl-carouselss'>
                    <div className="item"><img src={sponser9} alt="Image 9" /></div>
                    </div>
                </Slider>
      
      </div>
        </div>
        <div>
          
        </div>
   </section>
  );
}

export default BottomCarousel






