import React from 'react';
import ManImg from '../assets/images/testimonials_man.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function HappyClients() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          }
        ]
    };
    const paragraphStyle = {
        color: '#000',
        fontSize: '16px',
        fontWeight: 300,
        margin: '10px 0 0',
        padding: 0,
        textAlign: 'left'
    };

    const buttonStyle = {
        float: 'left',
        color: '#337ab7',
        fontSize: '20px',
        fontWeight: 400,
        margin: 0,
        padding: 0,
        textDecoration: 'none',
        paddingTop:'15px'
    };

    return (
        <section className="articles">
            <div className="container">
                <h2 className="what-we-dos"><span className="what-we-do">HAPPY</span> CLIENTS</h2>
                <hr className="animated-hrs" />
                <Slider {...settings}>
                 <div className='p-2 pb-5'>
                 <div className="boxss">
                        <img src={ManImg} alt="Man" style={{ width: '15%', height: 'auto' }} />
                        <h4>Executive - IT, MuSigma.</h4>
                        <p style={paragraphStyle}>The delivery was highly customized in accordance with varied levels of expertise. This sounds indispensable in corporate knowledge transfer.</p>
                        <div className="box-btn pb-3">
                            <a href="/testimonials" style={buttonStyle}>Read More</a>
                        </div>
                    </div>
                 </div>
                    <div className='p-2'>
                    <div className="boxss">
                        <img src={ManImg} alt="Man" style={{ width: '15%', height: 'auto' }} />
                        <h4>Manager - Netcon Technologies.</h4>
                        <p style={paragraphStyle}>Got really impressed with their methodology and tools, it was a great experience of professional learning.</p>
                        <div className="box-btn pb-3">
                            <a href="/testimonials" style={buttonStyle}>Read More</a>
                        </div>
                    </div>
                    </div>
                    <div className='p-2'>
                    <div className="boxss">
                        <img src={ManImg} alt="Man" style={{ width: '15%', height: 'auto' }} />
                        <h4>Regional Director-Channel | SAARC Region | extreme Networks</h4>
                        <p style={paragraphStyle}>Their operations helped our channel partner to a great extent in handling growing business and technical support services.</p>
                        <div className="box-btn pb-3">
                            <a href="/testimonials" style={buttonStyle}>Read More</a>
                        </div>
                    </div>
                    </div>
                </Slider>
            </div>
        </section>
    );
}

export default HappyClients;
