import React from 'react'
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Screens/Home';
import About from './Screens/About'
import Alliances from './Screens/Alliances'
import Testimonials from './Screens/Testimonials'
import Careers from './Screens/Careers'
import Contact from './Screens/Contact'
import AdvancedTechnology from './Screens/AdvancedTechnology';
import Domain from './Screens/Domian'
import ProfessionalService from './Screens/ProfessionalService'
import 'aos/dist/aos.css'; // Import the AOS styles 
import SoseMenu from './Screens/SoseMenu'
import BusinessMenu from './Screens/BusinessMenu'
import LeaderShipMenu from './Screens/LeaderingMenu'
import BehaviouralMenu from './Screens/BehaviouralMenu'
import CoachingMenu from './Screens/Coaching'
import CrossCultue from './Screens/CrossCulture'
function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/alliances" element={<Alliances/>} />
          <Route path="/testimonials" element={<Testimonials/>} />
          <Route path="/careers" element={<Careers/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/advanced-technology-domain" element={<AdvancedTechnology/>} />
          <Route path="/domain-compliances-gov" element={<Domain/>} />
          <Route path="/professional-service-domain" element={<ProfessionalService/>} />
          <Route path="/noetc-science" element={<SoseMenu/>} />
          <Route path="/business-skill" element={<BusinessMenu/>} />
          <Route path="/leadership-management" element={<LeaderShipMenu/>} />
          <Route path="/behavioural-skill-training" element={<BehaviouralMenu/>} />
          <Route path="/coaching" element={<CoachingMenu/>} />
          <Route path="/Cross-culture" element={<CrossCultue/>} />
          </Routes>
         </BrowserRouter>
</div>
  );
}
export default App;
