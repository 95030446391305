import React from 'react'
import '../Styles/Home.css'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import { Link } from 'react-router-dom'
import brixLogo from "../assets/images/brixLogo.png"
import menu from '../assets/images/menu.png'
import {   FaFacebook, FaTwitter,FaLinkedin,FaTelegram } from 'react-icons/fa';
import '../Styles/Navbar.css'
import BottomCarousel from '../Components/BottomCarousel'
import FeatureSection from '../Components/FeatureSection'
import HappyClients from '../Components/HappyClients'
import Video from '../assets/images/brixVedios.mp4'
function Home() {
   
const containerStyle = {
   padding: '56.25% 0 0 0',
   position: 'relative',
   background: 'linear-gradient(45deg, #333, #555)', // Slightly darker gradient background
   boxShadow: '0 5px 15px rgba(0, 0, 0, 0.5)', // Darker shadow
 };

 const iframeStyle = {
   position: 'absolute',
   top: 0,
   left: 0,
   width: '100%',
   height: '100%',
   border: 'none',
 };

return (
<div className="Appss">
   <header className="header ">
      <div className="container">
         <nav className="flex flex-jc-sb flex-ai-c background-transparent absolute">
            <div className="logo">
               <a href="/">
               <img src={brixLogo} alt="logo" />
               </a>
            </div>
            <button className="btn " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><img src={menu}></img></button>
            <div className="offcanvas offcanvas-end back-bg" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
               <div className="offcanvas-header">
                  <h5 className="offcanvas-title" id="offcanvasRightLabel">MENU</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
               </div>
               <div className="offcanvas-body">
                  <Link  to='/'>
                  Home</Link> 
                  <hr/>
               </div>
               <div className="offcanvas-body">
                  <Link to='/about'>
                  About</Link>
                  <hr/>
               </div>
               <div className="offcanvas-body">
                  <Link to="/alliances" className="dropdown-toggle" id="servicesDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Services
                  </Link>
                  <div className="dropdown-menu custom-dropdown-menu" aria-labelledby="servicesDropdown">
                     <div className="offcanvas-body">
                        <Link className="dropdown-item" to="/advanced-technology-domain">
                        Advance Technology Domain</Link>
                     </div>
                     <div className="offcanvas-body">
                        <Link className="dropdown-item" to="/domain-compliances-gov">
                        Domain Compliances & Governance</Link>
                     </div>
                     <div className="offcanvas-body">
                        <Link className="dropdown-item" to="/professional-service-domain">
                        Professional Service Domain</Link>
                     </div>
                     <div className="offcanvas-body paddind-fonts">
                        <Link >
                        Learning & Development Domain</Link>
                        <div className="offcanvas-body paddind-font">
                           <Link className="dropdown-item " to="/noetc-science">
                           -    S0SE / noetic Science</Link>
                        </div>
                        <div className="offcanvas-body paddind-font">
                           <Link className="dropdown-item" to="/business-skill">
                           -    Business Skill</Link>
                        </div>
                        <div className="offcanvas-body paddind-font">
                           <Link className="dropdown-item" to="/leadership-management">
                           -    Leadership and Management</Link>
                        </div>
                        <div className="offcanvas-body paddind-font">
                           <Link className="dropdown-item" to="/behavioural-skill-training">
                           -    Behavioural Skill Program</Link>
                        </div>
                        <div className="offcanvas-body paddind-font">
                           <Link className="dropdown-item" to="/coaching">
                           -    Coaching</Link>
                        </div>
                        <div className="offcanvas-body paddind-font">
                           <Link className="dropdown-item" to="/Cross-cultue">
                           -   Cross-Culture</Link>
                        </div>
                     </div>
                  </div>
                  <hr />
               </div>
               <div className="offcanvas-body">
                  <Link to='/alliances'>
                  Our Alliances</Link> 
                  <hr/>
               </div>
               <div className="offcanvas-body">
                  <Link to='/testimonials'>
                  Testimonials</Link> 
                  <hr/>
               </div>
               <div className="offcanvas-body">
                  <Link to='/careers'>
                  Careers</Link> 
                  <hr/>
               </div>
               <div className="offcanvas-body">
                  <Link to='/contact'>
                  Contacts</Link> 
                  <hr/>
               </div>
               <div className="offcanvas-body">
                  <ul className="social-media">
                     <li>
                        <a className="fb-ic mr-3" href="#">
                           <FaFacebook />
                        </a>
                     </li>
                     <li>
                        <a className="fb-ic mr-3" href="#">
                           <FaTwitter />
                        </a>
                     </li>
                     <li>
                        <a className="fb-ic mr-3" href="#">
                           <FaLinkedin />
                        </a>
                     </li>
                     <li>
                        <a className="fb-ic mr-3" href="#">
                           <FaTelegram />
                        </a>
                     </li>
                  </ul>
               </div>
            </div>
         </nav>
      </div>
      <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
         <div class="carousel-inner">
            <div class="carousel-item active">
               <div className='carousel-bg1'>
                  <div className='container'>
                     <div className='row'>
                        <div className='col-lg-6'>
                        </div>
                        <div className='col-lg-6'>
                           <div className="slider ">
                              <h2 className='pure animation-top-sentence'> <strong className='pure'>A Pure</strong> Play <strong className='pure'> Knowledge</strong></h2>
                              <h3 className='pure animation-secound-sentence'><strong className='pure'>Transfer</strong> Organization </h3>
                              <div className="slider_btn animation-btn-sentence" >
                                 <a href='/about'><button>Read More</button></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="carousel-item">
               <div className='carousel-bg2'>
                  <div className='containers'>
                     <div className='row'>
                        <div className='col-lg-6'>
                        </div>
                        <div className='col-lg-6'>
                           <div className="slider ">
                              <h2 className='pure animation-top-sentence'>enabling  <strong className='pure'>young minds</strong>  <strong className='pure'></strong></h2>
                              <h2 className='pure animation-top-sentence'> with lasting <strong className='pure'>efficiency</strong></h2>
                              <div className="slider_btn animation-btn-sentence" >
                                 <a href='/noetc-science'><button>Read More</button></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="carousel-item">
               <div className='carousel-bg3'>
                  <div className='containers'>
                     <div className='row'>
                        <div className='col-lg-4'>
                           <div className="slider">
                              <h2 className='pure animation-top-sentence'> <strong className='pure'>Unleash</strong> thy <strong className='pure'> creativity </strong></h2>
                              <h3 className='pure animation-secound-sentence'><strong className='pure'>within</strong></h3>
                              <div className="slider_btn animation-btn-sentence" >
                                 <a href='/advanced-technology-domain'><button>Read More</button></a>
                              </div>
                           </div>
                        </div>
                        <div className='col-lg-4'>
                        </div>
                        <div className='col-lg-4'>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="carousel-item">
               <div className='carousel-bg4'>
                  <div className='container'>
                     <div className='row'>
                        <div className='col-lg-6'>
                        </div>
                        <div className='col-lg-6'>
                           <div className="slider ">
                           <h2 className='pure animation-secound-sentence'> <strong className='pure'>Delivering </strong> TRUST,</h2>
                              <h2 className='pure animation-top-sentence'> <strong className='pure'>Your</strong> Preferred <strong className='pure'> Knowledge</strong></h2>
                              <h3 className='pure animation-secound-sentence'><strong className='pure'>Transfer</strong> Partner </h3>
                              <div className="slider_btn animation-btn-sentence" >
                                 <a href='/domain-compliances-gov'><button>Read More</button></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="carousel-item">
               <div className='carousel-bg5'>
                  <div className='container'>
                     <div className='row'>
                        <div className='col-lg-6'>
                           <div className="slider ">
                              <h2 className='pure animation-top-sentence'> <strong className='pure'></strong>Delivered by<strong className='pure'> Compliances</strong></h2>
                              <h3 className='pure animation-secound-sentence'><strong className='pure'>Awareness team</strong></h3>
                              <div className="slider_btn animation-btn-sentence" >
                                 <a href='/professional-service-domain'><button>Read More</button></a>
                              </div>
                           </div>
                        </div>
                        <div className='col-lg-6'>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
         <span class="carousel-control-prev-icon" aria-hidden="true"></span>
         <span class="visually-hidden">Previous</span>
         </button>
         <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
         <span class="carousel-control-next-icon" aria-hidden="true"></span>
         <span class="visually-hidden">Next</span>
         </button>
      </div>
   </header>
   <main className="main">
      <section className="features">
         <div className="success-bg">
            <div className="container ">
               <div className="row mt-5">
                  <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12" >
                     <div className="success-lefts">
                        <h2 className="what-we-doss "><span className="what-we-dossssss">SUCCESS</span> WORK</h2>
                        <hr className="animated-hr"/>
                        <p className="success-heading-para" >BRiX Network Pvt Ltd is a pure play Knowledge transfer Organization, was incorporated in November 2014, with ATP feature from extreme networks for PAN India. BRiX thrives in setting a benchmark in the Knowledge Transfer Domain and its Services by building a strong Business Compliance awareness team on its designated vertical delivery to its clientele, with an objective of ensuring value based, high quality and future-proof services. BRiX Networks has identified and partnered with world’s leading life science organization | OEMs for their Authorised Training Partner & Authorised Learning Center | Empaneled Subject Matter Experts | Lead auditors | Cyber Security Experts.</p>
                        <p className="success-heading-para">Integrating an eminent chain of corporate allies to assure steadfast delivery</p>
                        <p className="success-heading-para">At BRIX, we build a concrete bridge with a zeal to ensure value based, high quality and future-proof services.</p>
                        <div className="vission-mission">
                           <div className="row" >
                              <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                                 <div className="box">
                                    <h4 className="success-heading-mini">Our Vision</h4>
                                    <p className="success-heading-para">To enable, excellence at each level, process and service delivery.</p>
                                 </div>
                              </div>
                              <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                                 <div className="box">
                                    <h4 className="success-heading-mini">Our Mission</h4>
                                    <p className="success-heading-para">Our core values guide everything we do - Premium Knowledge Transfer, innovation and
professional service delivery that drives eminence.</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 padding-tops">
  <div>
    <div style={containerStyle}>
      <video
        src={Video} // Ensure Video variable holds the correct URL
        style={iframeStyle}
        autoPlay // Add the autoPlay attribute
        muted // Add the muted attribute
        controls // Add the controls attribute if you want to allow the user to pause/resume
      ></video>
    </div>
    <script src="https://player.vimeo.com/api/player.js"></script>
  </div>
</div>
               </div>
            </div>
         </div>
         <div className="mt-5">
            <div className="hwhatwedo">
               <div className="container " >
                  <h2 className="what-we-dos" ><span className="what-we-do">What</span> We Do</h2>
                  <hr className="animated-hrs" />
               </div>
            </div>
         </div>
         <FeatureSection/>
      </section>
      {/* Happy Clients Start */}
      <section className='Happy-clients'>
         <HappyClients/>
      </section>
      {/* Happy Clients END */}
      <section className='botton-carousel'>
         <BottomCarousel/>
      </section>
   </main>
   {/* <!------footer start--------> */}
   <Footer/>
   {/* <!------footer end--------> */}
</div>
);
}
export default Home;